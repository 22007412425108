import logo from "./Besta Logo violet copy.png";
import exemple from "./exemple.png";
import { Link } from "react-router-dom";

export default function Home(){
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="Besta"/>
            </header>
            <main>
                <div className="main">
                    <div className="left-div">
                        <h2>Trouve ta soirée</h2>
                        <h3>🕺 Besta te propose les meilleures soirées</h3>
                        <h3>
                            🎤 Les meilleures fêtes de village
                        </h3>
                        <h3>
                            🪩 Tout ça dans une application simple
                        </h3>
                        <p className="text-body">
                            Retrouve les meilleures soirées de ta région, soit informé des événements à venir et
                            retrouve toutes les informations nécessaires pour trouver la soirée parfaite.
                        </p>
                        <div className="dl-button">
                            <a href={"https://linktr.ee/besta_app?utm_source=linktree_profile_share&ltsid=d487ef1a-735d-4998-8c3c-6a96a11bd742"}>Télécharger l'application</a>
                        </div>
                        <Link className="policy-link" to={"/policy"}>Politique de confidentialité</Link>
                    </div>
                    <div>
                        <img src={exemple} alt="Besta"/>
                    </div>
                </div>
            </main>
        </div>
    )
}