import logo from "./Besta Logo violet copy.png";

export default function Policy() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="Besta" />
            </header>
            <main>
                <div className="main">
                    <article>
                        <h3>Politique de confidentialité</h3>
                        <section>
                            <h3>1. Introduction</h3>
                            <p className="text-policy">
                                Nous nous engageons à protéger et à respecter votre
                                vie privée. Cette politique de confidentialité explique comment nous collectons,
                                utilisons, divulguons et protégeons les informations personnelles que vous nous
                                fournissez lorsque vous utilisez notre application mobile.
                            </p>
                        </section>
                        <section>
                            <h3>2. Informations que nous collectons</h3>
                            <p className="text-policy">
                                Nous pouvons collecter et traiter les informations suivantes :
                            </p>
                            <ul>
                                <li>Données de localisation : Pour vous aider à trouver des événements à proximité.</li>
                                <li>Données d'utilisation : Informations sur la manière dont vous utilisez notre application, y compris les pages visitées, le temps passé sur l'application, etc.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>3. Utilisation des informations</h3>
                            <p className="text-policy">
                                Nous utilisons les informations que nous collectons pour :
                            </p>
                            <ul>
                                <li>Vous fournir et améliorer nos services.</li>
                                <li>Personnaliser votre expérience utilisateur.</li>
                                <li>Vous informer des événements et soirées proches de votre localisation.</li>
                                <li>Répondre à vos demandes de support et d'assistance.</li>
                                <li>Analyser l'utilisation de notre application pour améliorer nos fonctionnalités et services.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>4. Partage des informations</h3>
                            <p className="text-policy">
                                Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
                            </p>
                            <ul>
                                <li>Avec votre consentement explicite.</li>
                                <li>Pour se conformer à des obligations légales ou répondre à des demandes des autorités compétentes.</li>
                                <li>Pour protéger nos droits, notre propriété ou notre sécurité, ainsi que ceux de nos utilisateurs ou du public.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>5. Sécurité des données</h3>
                            <p className="text-policy">
                                Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos informations personnelles contre tout accès non autorisé, perte, destruction ou divulgation.
                            </p>
                        </section>
                        <section>
                            <h3>6. Conservation des données</h3>
                            <p className="text-policy">
                                Nous conservons vos informations personnelles aussi longtemps que nécessaire pour fournir nos services et pour se conformer aux obligations légales. Après cette période, nous supprimons ou anonymisons vos données.
                            </p>
                        </section>
                        <section>
                            <h3>7. Vos droits</h3>
                            <p className="text-policy">
                                Vous avez le droit :
                            </p>
                            <ul>
                                <li>d'accéder à vos informations personnelles.</li>
                                <li>de demander la correction de vos informations personnelles.</li>
                                <li>de demander la suppression de vos informations personnelles.</li>
                                <li>de vous opposer au traitement de vos informations personnelles.</li>
                                <li>de demander la limitation du traitement de vos informations personnelles.</li>
                                <li>de demander la portabilité de vos informations personnelles.</li>
                            </ul>
                            <p className="text-policy">
                                Pour exercer ces droits, veuillez nous contacter à : <a href="mailto:contact@besta-app.fr">contact@besta-app.fr</a>
                            </p>
                        </section>
                        <section>
                            <h3>8. Modifications de cette politique de confidentialité</h3>
                            <p className="text-policy">
                                Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle politique sur cette page. Nous vous encourageons à consulter cette politique périodiquement pour rester informé de la manière dont nous protégeons vos informations.
                            </p>
                        </section>
                        <section>
                            <h3>9. Contact</h3>
                            <p className="text-policy">
                                Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à : <a href="mailto:contact@besta-app.fr">contact@besta-app.fr</a>
                            </p>
                            <p className="text-policy">Date d'entrée en vigueur : 22 Mai 2024</p>
                        </section>
                    </article>
                </div>
            </main>
        </div>
    );
}
