import { Routes, Route } from 'react-router-dom';
import './App.css';

import Home from './Home';
import Policy from './Policy';


function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/policy" element={<Policy />} />
            </Routes>
        </div>
    );
}

export default App;
